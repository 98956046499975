<template>
  <v-card class="mt-0" style="padding:20px;">
    <h2 style="margin-bottom:10px;">历史考试列表</h2> 
    <v-data-table 
      item-key="id" 
      :headers="headers"
      :items="dataList"
      :options.sync="options"
      hide-default-footer
      class="elevation-1 worktab"
    > 
      <template v-slot:item.lookexaminfo="{ item }">
        <template>
          <v-btn text color="#437EE2" style="padding:0 !important;font-size:1rem;" @click="doLmind(item)">
            <v-icon :left="true">
              mdi-paperclip
            </v-icon>
            查看详情
          </v-btn>
        </template> 
      </template> 
    </v-data-table>
    <v-pagination
      style="padding:20px 0 0;"
      v-model="options.current"
      :length="options.pages"
    ></v-pagination> 
  </v-card>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapGetters } from "vuex";
export default {
  name: 'Kshistory',
  data: () => {
    return {
      loading: false,
      headers: [
        { text: '考试名称', value: 'paperName', sortable: false },
        { text: '考试完成时间', value: 'createTime', width: 200, align: 'center', sortable: false },
        { text: '考试得分', value: 'userScore', width: 150, align: 'center', sortable: false },
        { text: '试卷解析', value: 'lookexaminfo',  width: 120, align: 'center',sortable: false }
      ],
      dataList:[],
      total: 0,
      baseURL:'',
      options: {
        current: 1, // 当前页码，默认第一页
        size: 10, // 每页显示数量
        pages: 1
      }, 
    }
  },
  watch: {
    'options.current': {
      handler(val, oldVal) {
        this.getData()
      }
    }
  },
  created() {
    this.baseURL = window._CONFIG['staticDomainURL'] + '/'
    this.init() 
  }, 
  methods: {
    moment,
    ...mapGetters(["userInfo", 'token']),
    init() {
      this.options.current = 1 
      this.getData()
    },
    getData(){
      let username = this.userInfo().username
      let params = {
          "pageIndex": this.options.current,
          "pageSize": this.options.size, 
          "userName": username || ""
      }
      let examHistoryUrl = localStorage.getItem('examHistoryUrl') || 'http://192.168.0.7:8002'
      let url = examHistoryUrl+'/api/admin/examPaperAnswer/pageByUserName'
      axios({
        method: 'post',
        url: url,
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'ptoken' : this.token()
        }
      }).then(res => {
        if(res.status == '200'){ 
          let response = res.data && res.data.response || {}
          this.dataList = response.list || []
          this.total = response.total || 0
          this.options.pages = response.pages || 1
        }
      }).catch(error => {
        console.error(error);
      }); 
    },
    // 获取当前用户部门名称
    getCurrentDepartName(){
      let currDepartName = ""
      let userInfo = this.userInfo()
      let Departs = localStorage.getItem('pro__Login_Departs') ? JSON.parse(localStorage.getItem('pro__Login_Departs')).value : []
      if(userInfo.orgCode && Departs && Departs.length>0){
        for(let i of Departs){
          if(i.orgCode == userInfo.orgCode){
            currDepartName = i.departName
            break
          }
        }
      }
      return currDepartName
    },
    doLmind(row){ 
      // console.log(row, 'rowsss')
      let token = this.token()
      let realname = this.userInfo().realname 
      let dept = this.getCurrentDepartName()
      if(row && (row.link_address || row.id)) {
        let newUrl = ""
        if(row.link_address){
          newUrl = row.link_address+"?paper="+row.id+"&cnname="+realname+"&deptname="+dept+"&token="+token
        } else {
          let examInfoUrl = localStorage.getItem('examInfoUrl') || 'http://192.168.0.7:8002'
          newUrl = examInfoUrl + '/#/read?paper='+row.id+"&cnname="+realname+"&deptname="+dept+"&token="+token
        } 
        if(newUrl && newUrl !== "") window.open(newUrl, '考试查看')
      } 
    },
    loadData(){
      this.init()
    }
  }
}
</script>
<style scoped>
.worktab /deep/ td,.worktab /deep/ th{padding:0 5px !important;}
.worktab /deep/ th{font-size:1rem !important;color:#000 !important;background:#ccc;}
.workpre /deep/ .v-card__text{padding:5px;}
</style>
